import { primitives } from "@einride/ui"
import { SerializedStyles, css, useTheme } from "@emotion/react"
import { useSaga } from "@saga/core"

export const useCustomStyles = (): SerializedStyles => {
  const { isSidebarOpen } = useSaga()
  const theme = useTheme()
  return css`
    div[part="section-navbar-search"] {
      margin-top: 88px;
      padding-bottom: 24px;
    }
    div[part="section-navbar-search"] input {
      font-family: var(--font-regular);
      font-size: var(--font-size-regular);
    }
    div[part="section-navbar-search"] input::placeholder {
      color: ${theme.colors.content.tertiary};
    }
    div[part="section-navbar-search"] input + div {
      display: none;
    }
    div[part="section-navbar-search"] button.m-btn {
      width: fit-content;
    }
    .nav-scroll {
      margin: 24px 24px 24px 24px;
      transition: margin 360ms;
      transition-timing-curve: cubic-bezier(0.21, 0.69, 0.2, 1);
    }
    .main-content {
      ${!isSidebarOpen && "padding-top: 80px"};
      transition: all 360ms;
      transition-timing-curve: cubic-bezier(0.21, 0.69, 0.2, 1);
    }

    .m-markdown a {
      color: ${theme.colors.content.positive};
    }

    .col {
      position: relative;
    }

    .request-panel div div[style*="align-items:flex-end"] {
      justify-content: flex-end;
    }

    .request-panel div .hide-in-small-screen {
      display: none;
    }

    .response-panel .top-gap {
      margin-top: 0;
    }

    .response-panel div div[style*="flex-wrap:wrap"] {
      position: absolute;
      top: 48px;
      right: 0;
    }

    .response-panel .m-markdown-small {
      margin-top: 32px;
    }

    button {
      border: none;
      box-shadow: none;
      height: 48px;
      border-radius: 24px;
      padding: 0px 16px;
      text-transform: lowercase;
      font-weight: normal;
      font-size: 18px;
      padding: 12px 24px;
      margin-left: 12px;
      color: ${theme.colors.buttons.text.secondary};
      background-color: ${theme.colors.buttons.background.secondary};
    }

    button::first-letter {
      text-transform: capitalize;
    }

    button[part*="btn-selected-response-status"],
    button[part*="btn-try"],
    button[part*="btn-copy"],
    button.toolbar-btn,
    button.active {
      background-color: ${theme.colors.buttons.background.primary};
      color: ${theme.colors.buttons.text.primary};
    }

    input[part*="textbox"],
    .tags input[type="text"] {
      background-color: ${theme.colors.background.secondary};
      color: ${theme.colors.content.primary};
      border: none;
      border-radius: 24px;
      height: 48px;
      padding: 0px 16px;
    }

    .toolbar,
    button.toolbar-btn {
      top: ${theme.spacer * 2}px;
      right: ${theme.spacer * 2}px;
    }

    .tags input[type="text"]::placeholder {
      color: ${theme.colors.content.secondary};
    }

    .tags {
      background-color: ${theme.colors.background.primary};
      border: none;
    }

    .tags .tag {
      background-color: ${theme.colors.background.secondary};
      display: flex;
      padding: ${theme.spacer}px;
      border-radius: ${theme.spacer}px;
      border: none;
      align-items: center;
    }

    .json-tree,
    pre,
    textarea {
      background-color: ${theme.colors.background.secondary};
      padding: ${theme.spacer * 2}px;
      border-radius: ${theme.spacer * 2}px;
      border: none;
    }

    .example-panel {
      border: none;
    }

    .example-panel.pad-top-8 {
      padding-top: 0;
    }

    .tab-content {
      margin-top: 32px;
    }

    .nav-bar {
      border-right: 1px solid ${theme.colors.border.primary};
    }

    .nav-scroll div {
      font-size: 18px;
      font-weight: normal;
    }

    /* Services */
    .nav-bar-tag {
      margin-top: 40px;
      background-color: ${theme.colors.background.primary};
      border-radius: 8px;
      border: none;
      padding: 16px 8px;
    }

    .nav-bar-tag div {
      color: ${theme.colors.content.secondary};
    }

    #link-operations-top {
      display: none;
    }

    .nav-bar-info {
      background-color: ${theme.colors.background.primary};
      border-radius: 8px;
      font-size: 18px;
      padding: 16px 8px;
      border: none;
    }

    .nav-bar-info.active {
      background-color: ${theme.colors.background.secondary};
    }

    .nav-bar-path {
      background-color: ${theme.colors.background.primary};
      height: 48px;
      padding: 16px 8px;
      font-size: 18px;
      border-radius: 8px;
      border: none;
    }

    .nav-bar-path.active {
      background-color: ${theme.colors.background.secondary};
    }

    /* Content */

    .section-gap--focused-mode {
      padding: 24px;
    }

    h3 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      text-transform: capitalize;
      color: ${theme.colors.content.primary};
    }

    h3.upper {
      color: ${theme.colors.content.secondary};
    }

    h2 {
      font-size: 28px;
      line-height: 32px;
      font-weight: 500;
      text-transform: capitalize;
      color: ${theme.colors.content.primary};
    }

    .label-operation-path {
      color: ${theme.colors.content.secondary};
    }

    :host {
      --fg: ${theme.colors.content.primary};
      --fg3: ${theme.colors.content.secondary};
      --blue: ${primitives.color.blue[40]};
      --light-fg: rgba(30, 30, 31, 0.6);
      --green: #00a500;
      --red: #d0190c;
      --font-size-regular: 18px;
    }

    .upper {
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
    }

    .expanded-req-resp-container {
      padding-top: 72px;
    }

    .req-res-title {
      font-size: 18px;
      line-height: 1;
      font-weight: 450;
      text-transform: lowercase;
      padding-top: ${theme.spacer * 3}px;
      color: ${theme.colors.content.primary};
      border-top: 1px solid ${theme.colors.border.primary};
      margin: 0;
    }

    .req-res-title::first-line {
      text-transform: capitalize;
    }

    .method-fg.get {
      color: var(--blue);
    }

    .method-fg.get::after {
      content: " ↓";
    }

    .method-fg.post {
      color: var(--green);
    }

    .method-fg.post::after {
      content: " ↑";
    }

    .focused-mode {
      border-top: none;
    }

    /* Tables*/

    .table-title {
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      text-transform: lowercase;
      color: ${theme.colors.content.secondary};
      margin-bottom: 56px;
    }

    .table-title::first-letter {
      text-transform: capitalize;
    }

    .table-title.top-gap,
    .resp-descr p {
      margin-top: 4px;
      color: ${theme.colors.content.secondary};
    }

    .resp-descr {
      margin-bottom: 32px;
    }

    .resp-panel .top-gap {
      margin-top: 0;
    }

    .tab-panel .tab-buttons {
      margin-top: 24px;
      margin-bottom: 0;
      border: none;
    }

    table {
      border: none;
    }

    .m-table {
      border: none;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
    }

    .m-table tr td {
      height: 80px;
      background-color: ${theme.colors.background.primary};
      border-bottom: 1px solid #dadada;
    }

    .m-table tr td:first-child {
      height: 80px;
      border-bottom: none;
    }

    .m-table tr:last-of-type td {
      height: 80px;
      border-bottom: none;
    }

    .m-table tr td {
      padding: 16px;
    }

    .m-table tr td p {
      padding: 0;
      margin: 0;
      font-size: 14px;
      line-height: 18px;
      color: ${theme.colors.content.secondary};
    }

    .param-name,
    .param-type {
      text-align: left;
      font-size: 18px;
      line-height: 24px;
    }

    .param-type {
      color: ${theme.colors.content.secondary};
    }

    .param-constraint {
      text-align: left;
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
    }

    .tab-buttons {
      height: auto;
    }

    .request-body-container {
      margin-top: 48px;
    }

    .request-body-container .table-title {
      margin-bottom: 0;
      color: ${theme.colors.content.primary};
    }

    .request-body-container .table-title span:nth-of-type(2) {
      font-size: 14px;
    }

    .request-body-container .m-markdown p {
      color: ${theme.colors.content.secondary};
      margin: 0;
    }
  `
}
