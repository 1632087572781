import { NotFoundPage } from "pages/NotFoundPage"
import { RootLayout } from "pages/RootLayout"
import { StartPage } from "pages/StartPage"
import { RouterProvider, createBrowserRouter } from "react-router-dom"

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      { path: "*", element: <NotFoundPage /> },
      {
        path: "/",
        element: <StartPage />,
      },
      {
        path: "/extendv1",
        element: <StartPage specUrl="extendv1.openapi.json" />,
      },
    ],
  },
])

export const App = (): React.JSX.Element => {
  return <RouterProvider router={router} />
}
