import type { useSagaAuth } from "@saga/core"
import { Region } from "@saga/core"

type AuthContext = ReturnType<typeof useSagaAuth>

const DEFAULT_REGION = "EU"

const ApiBaseUrls = {
  EU: import.meta.env.VITE_API_EU_BASE_URL,
  US: import.meta.env.VITE_API_US_BASE_URL,
}

export const resolveCurrentRegion = (auth: AuthContext): Region => {
  return auth.activeSpace?.region ?? auth.activeOrganization?.region ?? DEFAULT_REGION
}

export const getApiRegionUrl = (region: Region): string => {
  return ApiBaseUrls[region]
}
