import { App } from "App"
import { applyFetchPatch } from "fetchPatch"
import { initI18n } from "lib/i18n"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"

applyFetchPatch()

const container = document.getElementById("root")
if (!container) throw new Error("Failed to find the root container")
const root = createRoot(container)

initI18n()

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
