import { SagaCoreProvider, SagaMode } from "@saga/core"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { Outlet } from "react-router-dom"

const queryClient = new QueryClient()

const getMode = (): SagaMode => {
  if (import.meta.env.MODE === "production") return "production"
  if (import.meta.env.MODE === "staging") return "staging"
  return "development"
}

export const RootLayout = (): React.JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <SagaCoreProvider
        mode={getMode()}
        app="EXTEND"
        settingsProviderOptions={{ showSettingsInSidebar: true }}
      >
        <Outlet />
      </SagaCoreProvider>
    </QueryClientProvider>
  )
}
