import type { MutableRefObject } from "react"
import { useEffect } from "react"

const config: MutationObserverInit = {
  attributes: true,
  characterData: true,
  childList: true,
  subtree: true,
}

/**
 *
 * useShadowRootMutationObserver hook
 *
 * Returns a mutation observer for a React Ref's shadowRoot and fires a callback
 *
 * @param {MutableRefObject<HTMLElement | null>} ref React ref on which mutations are to be observed
 * @param {MutationCallback} callback Function that needs to be fired on mutation
 * @param {MutationObserverInit} options
 */
function useShadowRootMutationObserver(
  ref: MutableRefObject<HTMLDivElement | null>,
  callback: MutationCallback,
  options: MutationObserverInit = config,
): void {
  useEffect(() => {
    // Create an observer instance linked to the callback function
    if (ref.current) {
      const observer = new MutationObserver(callback)

      if (ref.current.shadowRoot) {
        observer.observe(ref.current.shadowRoot, options)
      }

      return () => {
        observer.disconnect()
      }
    }
    return undefined
  }, [callback, options, ref])
}

export { useShadowRootMutationObserver }
