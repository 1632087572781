import { Link, Text, VerticalSpacing } from "@einride/ui"
import { Saga, SagaNavbar, SagaPage, SagaSidebar } from "@saga/core"

export const NotFoundPage = (): React.JSX.Element => {
  return (
    <Saga navbar={<SagaNavbar />} sidebar={<SagaSidebar />}>
      <SagaPage>
        <Text variant="titleLg">Page not found</Text>
        <VerticalSpacing />
        <Text>The link might be wrong or the page may have been moved.</Text>
        <Link href="/">Go back to start page</Link>
      </SagaPage>
    </Saga>
  )
}
