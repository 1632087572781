import { PageLoader, Select } from "@einride/ui"
import { useTheme } from "@emotion/react"
import { Saga, SagaNavbar, SagaPage, SagaSidebar, useSagaAuth } from "@saga/core"
import { RapiDocReact } from "components/RapiDocReact"
import { getApiRegionUrl, resolveCurrentRegion } from "lib/api/region"
import { ChangeEvent, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export const StartPage = (opt: { specUrl?: string }): React.JSX.Element => {
  const theme = useTheme()
  const auth = useSagaAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const [token, setToken] = useState<string | undefined>(undefined)
  const [tokenFetched, setTokenFetched] = useState(false)
  useEffect(() => {
    auth
      .getToken()
      .then((authToken) => {
        setToken(authToken)
      })
      .finally(() => {
        setTokenFetched(true)
      })
  }, [auth])
  const region = resolveCurrentRegion(auth)
  const baseUrl = getApiRegionUrl(region)
  const { specUrl } = opt

  const navigateToVersion = (event: ChangeEvent<HTMLSelectElement>): void => {
    navigate(event.target.value)
  }

  const versionSelect = (
    <Select onChange={navigateToVersion} aria-label="select API version">
      <option selected={location.pathname === "/"} value="/">
        Extend v1beta
      </option>
      <option selected={location.pathname === "/extendv1"} value="/extendv1">
        Extend v1
      </option>
    </Select>
  )

  return (
    <Saga isFullScreen navbar={<SagaNavbar actions={versionSelect} />} sidebar={<SagaSidebar />}>
      <SagaPage style={{ margin: 0 }}>
        {!tokenFetched && <PageLoader />}
        {tokenFetched && (
          <RapiDocReact
            spec-url={specUrl || "extend.openapi.json"}
            render-style="read"
            allow-search
            allow-advanced-search={false}
            allow-authentication
            api-key-name="authorization"
            api-key-location="header"
            api-key-value={token ?? ""}
            show-header={false}
            nav-bg-color={theme.colors.background.primary}
            nav-text-color={theme.colors.content.primary}
            nav-accent-color={theme.colors.content.primary}
            schema-style="table"
            server-url={baseUrl}
            regular-font={theme.fonts.body}
            mono-font={theme.fonts.mono}
            primary-color={theme.colors.content.primary}
            bg-color={theme.colors.background.primary}
            schema-description-expanded
          />
        )}
      </SagaPage>
    </Saga>
  )
}
