const SPACES_PATH_REGEX = /(\/v1beta1\/)(spaces%2F[^?]+)/

/**
 * This is a hack to overcome resource names to be url encoded.
 * Problem is that RapiDoc has neither hook nor option to change
 * the request before the actual call.
 * In the `before-try` event the Request is present, but it is
 * immutable by design.
 */
export const applyFetchPatch = (): void => {
  const { fetch: originalFetch } = window

  window.fetch = async (request, ...rest) => {
    let outboundRequest: RequestInfo | URL = request

    if (request instanceof Request && request.url.match(SPACES_PATH_REGEX)) {
      outboundRequest = new Request(replaceSpacePath(request.url), request)

      return originalFetch(outboundRequest)
    }

    return originalFetch(request, ...rest)
  }
}

function replaceSpacePath(path: string): string {
  return path.replace(SPACES_PATH_REGEX, (_, apiPrefix, spacePath) => {
    return apiPrefix + spacePath.replace(/%2F/g, "/")
  })
}
